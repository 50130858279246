import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import APIComponent from "./APIComponent";

const FolderComponent = (props) => {
  const { folder, index } = props;
  const [isVisble, setIsVisble] = useState(false);
  const handleChange = () => {
    setIsVisble(!isVisble);
  };

  const getFileCounts = (item) => {
    let folders = 0;
    let api = 0;
    item.map((val) => {
      if (val.item) {
        folders += 1;
      } else if (val.request) {
        api += 1;
      }
    });
    return { folders, api };
  };
  const filteredDataMemoized = useMemo(() => {
    return folder;
  }, [folder]);

  return (
    <Accordion
      key={index}
      // style={{ backgroundColor: "#e9e9e9", marginBottom: "10px" }}
      expanded={isVisble}
      onChange={handleChange}
      id={`parent-${index}`}
    >
      <AccordionSummary
        style={{
          backgroundColor: "#f6f8fa",
          borderBottomColor: "#000000AA",
          borderBottomWidth: 1,
        }}
        className="main-accordion-summary"
        expandIcon={<ExpandMoreIcon />}
      >
        <div className="w-100 d-flex justify-content-between align-items-center">
          <Typography>{filteredDataMemoized?.name}</Typography>
          <Typography className="px-3">{`[${
            filteredDataMemoized?.item &&
            `${getFileCounts(filteredDataMemoized?.item).folders} / ${
              getFileCounts(filteredDataMemoized?.item).api
            }`
          }]`}</Typography>
        </div>
      </AccordionSummary>
      {isVisble && (
        <AccordionDetails>
          {filteredDataMemoized?.item.map((x, subIndex) => {
            if (!!x?.item)
              return (
                <FolderComponent
                  index={`${index}-subindex-${subIndex}`}
                  folder={x}
                  key={subIndex}
                />
              );
            else
              return (
                <APIComponent
                  apiData={x}
                  apiIndex={`${index}-subindex-apiindex-${subIndex}`}
                  key={`${index}-subindex-apiindex-${subIndex}`}
                />
              );
          })}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default FolderComponent;
