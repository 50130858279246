import { Navigate, useRoutes } from "react-router-dom";

import ForgetPassword from "./pages/LoginSignup/ForgetPassword";
import Login from "./pages/LoginSignup/Login";
import Signup from "./pages/LoginSignup/Signup";
import Header from "./Layouts/Header/Header";
import HomeLayout from "./Layouts/Home/HomeLayout";
import Project from "./pages/Project/Project";
import Home from "./pages/Home/Home";
import ViewUserProfile from "./pages/UserProfile/ViewUserProfile";

//pages

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
        element: <Header />,
      children: [
        { path: "/", element: <Navigate to="/home" /> },
        { path: "home", element: <Home /> },
        { path: "project/:project_id", element: <Project /> },
        { path: "user-profile", element: <ViewUserProfile /> },
      ],
    },

    {
      path: "/",
      element: <HomeLayout />,
      children: [
        
        { path: "login", element: <Login /> },
        { path: "signup", element: <Signup /> },
        { path: "forget-password", element: <ForgetPassword /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
