import React, { useState } from "react";
import { Add, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  _add_workspace,
  _delete_workspace,
  _edit_workspace,
} from "../../DAL/workspace";
import { useSnackbar } from "notistack";

const WorkspaceDialogs = (props) => {
  const {
    openDialog,
    setOpenDialog,
    newWorkspace,
    setNewWorkspace,
    workspaceArray,
    openEdit,
    setOpenEdit,
    workspaceList,
    editWorkspace,
    setEditWorkspace,
    openDelete,
    setOpenDelete,
    workspaceID,
    setWorkspace,
  } = props;

  const [summary, setSummary] = useState("");
  const [access, setAccess] = useState("personal");
  const { enqueueSnackbar } = useSnackbar();

  const handleClickClose = () => {
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    setAccess(event.target.value);
  };
  
  const handleChangeAccessType = (e) => {
    console.log(e.target.value,"access------")
    setEditWorkspace((prevWorkspace) => ({
      ...prevWorkspace,
      access_type: e.target.value,
    }))
  };



  const handleAddWorkspace = async () => {
    const reqObj = {
      name: newWorkspace,
      summary: summary,
      access_type: access,
    };
    const result = await _add_workspace(reqObj);
    if (result.code == 200) {
      workspaceArray.push(result.workspace);
      setWorkspace(result.workspace);
      setNewWorkspace("");
      setSummary("")
    } else {
      console.log(`Error: ${result.code}`);
      enqueueSnackbar(result.message, { variant: "error" });
    }

    setNewWorkspace("");
    handleClickClose();
  };
  //   edit dialog
  const handleCloseEditModal = () => {
    setOpenEdit(false);
  };

  const handleEditWorkspace = async (e) => {
    e.preventDefault();
    const reqObj = {
      name: editWorkspace.name,
      order: editWorkspace.order,
      summary: editWorkspace.summary,
      access_type: editWorkspace.access_type,
    };
    const result = await _edit_workspace(reqObj, editWorkspace._id);
    if (result.code == 200) {
      setEditWorkspace({ ...editWorkspace, name: result.workspace.name });
      workspaceList();
    } else {
      console.log(`Error: ${result.code}`);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    handleCloseEditModal();
  };

  //  delete dialog
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteWorkspace = async (ws_id) => {
    if (ws_id) {
      const result = await _delete_workspace(ws_id);
      if (result.code == 200) {
        if(localStorage.getItem('workspace')){
          const local_storage = JSON.parse(localStorage.getItem('workspace'))
          if(local_storage._id === ws_id){
            localStorage.removeItem('workspace');
          }
        }
        workspaceList();
      } else {
        console.log(`Error: ${result.code}`);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
    // const arr = workspaceArray.filter((val, order) => {
    //   if (workspaceArray.length < 2) {
    //     return val;
    //   } else if (workspaceIndex !== order) {
    //     return val;
    //   }
    // });
    handleCloseDelete();
    // return setWorkspaceArray(arr);
  };

  return (
    <>
      {/* add workspace dialog */}
      <Dialog
        open={openDialog}
        onClose={handleClickClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="d-flex justify-content-end pt-1 pb-0">
          <IconButton onClick={handleClickClose} aria-label="delete">
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>

        <DialogContent className="pb-1">
          <DialogContentText className="mb-2" style={{ fontSize: "18px" }}>
            Do you want to add a new workspace?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="workspace"
            label="Workspace"
            value={newWorkspace}
            fullWidth
            size="small"
            onChange={(e) => setNewWorkspace(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="summary"
            label="Summary"
            value={summary}
            fullWidth
            multiline
            maxRows={3}
            size="small"
            onChange={(e) => setSummary(e.target.value)}
          />
          <FormControl margin="dense">
            <FormLabel id="demo-controlled-radio-buttons-group">
              Who can access your workspace?
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={access}
              onChange={handleChange}
            >
              <FormControlLabel
                style={{
                  height: "20px",
                  marginTop: "15px",
                  fontSize: "14px !important",
                  fontWeight: "600 !important",
                }}
                value="personal"
                control={<Radio size="small" />}
                label="Personal"
              />
              <FormHelperText
                style={{ fontSize: "14px", marginTop: "0", marginLeft: "28px" }}
              >
                Only you can access
              </FormHelperText>

              <FormControlLabel
                style={{
                  height: "20px",
                  marginTop: "15px",
                  fontSize: "14px !important",
                  fontWeight: "600 !important",
                }}
                value="team"
                control={<Radio size="small" />}
                label="Team"
              />
              <FormHelperText
                style={{ fontSize: "14px", marginTop: "0", marginLeft: "28px" }}
              >
                All team members can access
              </FormHelperText>

              <FormControlLabel
                style={{
                  height: "20px",
                  marginTop: "15px",
                  fontSize: "14px !important",
                  fontWeight: "600 !important",
                }}
                value="public"
                control={<Radio size="small" />}
                label="Public"
              />
              <FormHelperText
                style={{ fontSize: "14px", marginTop: "0", marginLeft: "28px" }}
              >
                Everyone can view
              </FormHelperText>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "16px",
          }}
        >
          <div>
            <Button
              onClick={handleAddWorkspace}
              size="small"
              endIcon={<AddCircleIcon />}
              variant="contained"
              style={{
                lineHeight: "unset",
                textTransform: "capitalize",
                fontSize: "12px",
              }}
            >
              Add
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* end */}

      {/* edit workspace dialog */}
      <Dialog
        open={openEdit}
        onClose={handleCloseEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="d-flex justify-content-end pt-1 pb-0">
          <IconButton onClick={handleCloseEditModal} aria-label="delete">
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>
        <form
          onSubmit={(e) => handleEditWorkspace(e)}
          noValidate={false}
          autoComplete="off"
        >
          <DialogContent>
            <DialogContentText className="mb-2" style={{ fontSize: "18px" }}>
              Do you want to edit workspace?
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="workspace"
              label="Workspace"
              value={editWorkspace?.name}
              fullWidth
              size="small"
              onChange={(e) =>
                setEditWorkspace((prevPerson) => ({
                  ...prevPerson,
                  name: e.target.value,
                }))
              }
            />
            <TextField
              autoFocus
              margin="dense"
              id="summary"
              label="Summary"
              value={editWorkspace?.summary}
              fullWidth
              multiline
              maxRows={3}
              size="small"
              onChange={(e) =>
                setEditWorkspace((prevWorkspace) => ({
                  ...prevWorkspace,
                  summary: e.target.value,
                }))
              }
            />
            <FormControl margin="dense">
              <FormLabel id="demo-controlled-radio-buttons-group">
                Who can access your workspace?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={editWorkspace?.access_type}
                onChange={(e) =>handleChangeAccessType(e)
                }
              >
                <FormControlLabel
                  style={{
                    height: "20px",
                    marginTop: "15px",
                    fontSize: "14px !important",
                    fontWeight: "600 !important",
                  }}
                  value="personal"
                  control={<Radio size="small" />}
                  label="Personal"
                />
                <FormHelperText
                  style={{
                    fontSize: "14px",
                    marginTop: "0",
                    marginLeft: "28px",
                  }}
                >
                  Only you can access
                </FormHelperText>

                <FormControlLabel
                  style={{
                    height: "20px",
                    marginTop: "15px",
                    fontSize: "14px !important",
                    fontWeight: "600 !important",
                  }}
                  value="team"
                  control={<Radio size="small" />}
                  label="Team"
                />
                <FormHelperText
                  style={{
                    fontSize: "14px",
                    marginTop: "0",
                    marginLeft: "28px",
                  }}
                >
                  All team members can access
                </FormHelperText>

                <FormControlLabel
                  style={{
                    height: "20px",
                    marginTop: "15px",
                    fontSize: "14px !important",
                    fontWeight: "600 !important",
                  }}
                  value="public"
                  control={<Radio size="small" />}
                  label="Public"
                />
                <FormHelperText
                  style={{
                    fontSize: "14px",
                    marginTop: "0",
                    marginLeft: "28px",
                  }}
                >
                  Everyone can view
                </FormHelperText>
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              paddingLeft: "25px",
              paddingRight: "25px",
              paddingBottom: "16px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              style={{
                lineHeight: "unset",
                textTransform: "capitalize",
                fontSize: "12px",
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* end */}

      {/* delete workspace dialog */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="d-flex justify-content-end pt-1 pb-0"
          id="alert-dialog-title"
        >
          <IconButton onClick={handleCloseDelete} aria-label="delete">
            <CloseIcon sx={{ width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-0">
          <DialogContentText
            id="alert-dialog-description"
            className="pb-0"
            style={{ fontSize: "18px" }}
          >
            Do you realy want to delete workspace?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "16px",
          }}
        >
          <Button
            style={{
              lineHeight: "unset",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            variant="contained"
            onClick={handleCloseDelete}
          >
            Cancel
          </Button>
          <Button
            style={{
              lineHeight: "unset",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            variant="contained"
            onClick={() => handleDeleteWorkspace(workspaceID)}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* end */}
    </>
  );
};

export default WorkspaceDialogs;
