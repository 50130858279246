import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { _email_verification } from "../../DAL/auth";

export default function ForgetPassword () {
  const navigate = useNavigate();
  // states
  const [email, setEmail] = useState("");
  // ------------

  const handleSendEmail = async () => {
    const formData = new FormData();
    formData.append("email", email);
    const result = await _email_verification(formData);
    if (result.code === 200) {
      console.log(result, "codesent-------");
      localStorage.setItem("email", result.email);
      setEmail("");
      navigate("/verification-code")
    } else {
      console.log(`Error: ${result.code}`);
    }
  };

  return (
    <div className="screen-size py-5">
      <div className="container">
        <div className="postman-signup d-flex flex-column align-items-center justify-content-center px-5 text-center shadow-lg bg-body rounded">
          <h1 className="mb-4">Password Reset</h1>
          <p>you will receive instructions for reseting your password.</p>
          <TextField
            sx={{ m: 1 }}
            className="email-field mb-2"
            id="outlined-controlled"
            placeholder="Email"
            size="small"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <Button
            className="mt-4 d-flex align-items-center"
            size="small"
            variant="contained"
            endIcon={<EmailIcon />}
            style={{
              borderRadius: "30px",
              color: "#fff",
              padding: "15px 100px",
            }}
            onClick={() =>handleSendEmail()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};


