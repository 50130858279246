import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/material/styles";
import EnvironmentDialogs from "./EnvironmentDialogs";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { useAppContext } from "../../hooks/AppContext";
import { _add_project } from "../../DAL/project";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";

// Modal Style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #ccc",
  boxShadow: 24,
  p: 4,
};
// -----------

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const NewProjectModal = (props) => {
  const {
    openModal,
    setOpenModal,
    workspace,
    environmentDropdownValue,
    setEnvironmentDropdownValue,
    handleOpenProject,
    environmentArray,
    setSelectedEnvironment,
    projectList,
    addGrid,
    setAddGrid,
  } = props;

  const { setNewProject } = useAppContext();

  const [projectName, setProjectName] = useState("");

  // const [temp, setTemp] = useState("");

  const [file, setFile] = useState();

  const [openAddEnvironment, setOpenAddEnvironment] = useState(false);

  const [newEnvironment, setNewEnvironment] = useState("");

  const [newEnvironmentName, setNewEnvironmentName] = useState();

  const [openMenu, setOpenMenu] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseModal = async () => {
    setOpenModal(false);
    setProjectName("");
    setAddGrid([
      {
        environment: "",
        url: "",
      },
    ]);
    setFile("");
  };

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    if (projectName == "") {
      enqueueSnackbar("Please enter project name!", { variant: "error" });
      return;
    }
    if (file.length == 0) {
      enqueueSnackbar("Please add file!", { variant: "error" });
      return;
    }
    setLoading(true);
    setNewProject([
      {
        workspace: workspace._id,
        project: projectName,
        environment: addGrid,
      },
    ]);

    const formData = new FormData();
    formData.append("name", projectName);
    formData.append("status", "true");
    formData.append("file", file);
    formData.append("workspace", workspace._id);
    formData.append("environment", JSON.stringify(addGrid));
    console.log([...formData], "formData");
    const result = await _add_project(formData);
    console.log(result, "result------");

    if (result.code == 200) {
      // setPostmanFile(result.project);
      if (localStorage.getItem("workspace")) {
        const local_ws_id = JSON.parse(localStorage.getItem("workspace"));
        projectList(local_ws_id);
      }
      handleOpenProject(result.project._id)
      console.log(result, "lkasjdlkasjdlaskjdlaskjd");

      setLoading(false);
      setOpenModal(false);
      setProjectName("");
      setAddGrid([
        {
          environment: "Develepment",
          url: "",
        },
      ]);
      setFile("");
    } else {
      console.log(`Error: ${result.code}`);
      setLoading(false);
    }
    setSelectedEnvironment([...addGrid]);
  };

  const handleAddNewField = () => {
    addGrid.push({ environment: "", url: "" });
    setAddGrid([...addGrid]);
  };
  const handleRemoveField = (index) => {
    const arr = addGrid.filter((val, order) => {
      if (index !== order) {
        return val;
      }
    });
    setAddGrid(arr);
  };
  // environment menu

  const [anchorElEnvironment, setAnchorElEnvironment] = React.useState(null);
  const openEnvironment = Boolean(anchorElEnvironment);

  const handleMenuItemClick = (e, index) => {
    console.log(addGrid, "cxxxxxxxxxxxxxxxxxxzzzzz");
    setAddGrid((prev) => {
      let temp = prev;
      temp[index] = {
        ...temp[index],
        environment: e.target.value,
      };
      return [...temp];
    });
    setAnchorElEnvironment(null);
  };

  const handleClickOpen = () => {
    setOpenMenu(true);
  };

  const handleClose = () => {
    setNewEnvironmentName("");
    setOpenMenu(false);
  };

  const handleSaveEnvironment = () => {
    environmentDropdownValue.push({ environment: newEnvironmentName, url: "" });
    handleClose();
    setNewEnvironmentName("");
  };

  const handleRemoveEnvironment = (index) => {
    const arr = environmentDropdownValue.filter((val, order) => {
      if (index !== order) {
        return val;
      }
    });
    // setSelectedIndex(index)
    return setEnvironmentDropdownValue(arr);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "scroll" }}
      >
        <Box sx={style} className="py-0" style={{ borderRadius: "10px" }}>
          <div className="d-flex justify-content-between align-items-center my-2">
            <h6>Add New Project</h6>
            <IconButton onClick={handleCloseModal} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <TextField
                variant="outlined"
                label="Project Name"
                size="small"
                fullWidth
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
            {addGrid?.map((val, index, obj) => {
              return (
                <div className="row mb-3 pe-0" key={index}>
                  <div className="col-md-4">
                    <FormControl size="small" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Environment
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-simple-select"
                        style={{ height: "40px" }}
                        value={addGrid[index].environment}
                        label="Environment"
                        onChange={(e) => handleMenuItemClick(e, index)}
                        renderValue={(selected) => (
                          <ListItemText primary={selected} />
                        )}
                      >
                        {environmentDropdownValue.map((option, subIndex) => (
                          <MenuItem
                            className="d-flex justify-content-between align-items-center"
                            value={option.environment}
                            key={index}
                          >
                            <ListItemText primary={option.environment} />

                            <RemoveCircleOutlineIcon
                              className="ms-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveEnvironment(subIndex);
                              }}
                            />
                          </MenuItem>
                        ))}
                        <Divider />
                        <MenuItem
                          className="justify-content-center py-0"
                          onClick={() => handleClickOpen()}
                        >
                          <Add />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-8 p-0">
                    <TextField
                      id="outlined-basic"
                      label="Url"
                      type="text"
                      value={val.url}
                      onChange={(e) =>
                        setAddGrid((prev) => {
                          prev[index] = {
                            ...prev[index],
                            url: e.target.value,
                          };
                          return [...prev];
                        })
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveField(index)}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div className="col-md-12 mb-3 d-flex justify-content-end align-items-center">
              <Button
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
                onClick={() => handleAddNewField()}
                endIcon={<PostAddIcon />}
              >
                Add More
              </Button>
            </div>

            <Grid
              item
              xs={12}
              className="d-flex justify-content-between align-items-center pb-3"
            >
              <div>
                <IconButton
                  color="primary"
                  aria-label="upload json"
                  component="label"
                  style={{
                    border: "1px solid",
                    borderRadius: "5px",
                    padding: "5px 10px",
                  }}
                >
                  <Typography variant="p" fontSize="medium" className="me-2">
                    {file ? file.name : "Choose File"}
                  </Typography>
                  <input
                    hidden
                    accept="application/json"
                    type="file"
                    onChange={(e) => handleChange(e)}
                  />
                  <FileUploadIcon />
                </IconButton>
              </div>
              {/* <Button
                size="small"
                variant="contained"
                onClick={() => handleSave()}
                style={{
                  lineHeight: "unset",
                  textTransform: "capitalize",
                  fontSize: "12px",
                }}
              >
                Save
              </Button> */}
              <LoadingButton
                endIcon={<SendIcon />}
                loading={loading}
                size="small"
                variant="contained"
                loadingPosition="end"
                onClick={() => handleSave()}
                style={{
                  lineHeight: "unset",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  padding: "8px 15px",
                }}
              >
                Create
              </LoadingButton>
            </Grid>
          </div>
        </Box>
      </Modal>
      <EnvironmentDialogs
        newEnvironment={newEnvironment}
        setNewEnvironment={setNewEnvironment}
        environmentArray={environmentArray}
        setOpenAddEnvironment={setOpenAddEnvironment}
        openAddEnvironment={openAddEnvironment}
      />
      {/* new environment dialog */}
      <Dialog
        open={openMenu}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-end pt-1 pb-0"
        >
          <IconButton onClick={handleClose} aria-label="delete">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h6 className="mb-2">Do you want to add a new environment?</h6>
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="Environment"
              variant="outlined"
              value={newEnvironmentName}
              onChange={(e) => setNewEnvironmentName(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "5px 24px 10px" }}>
          <Button
            variant="contained"
            style={{
              lineHeight: "unset",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              lineHeight: "unset",
              textTransform: "capitalize",
              fontSize: "12px",
            }}
            onClick={() => handleSaveEnvironment()}
            autoFocus
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewProjectModal;
