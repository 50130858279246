import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import React, { useState } from "react";

import { styled, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Box,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Logout } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import WorkspaceDialogs from "../../pages/Components/WorkspaceDialogs";
import NewProjectModal from "../../pages/Components/NewProjectModal";
import { useEffect } from "react";
import { _user_logout } from "../../DAL/auth";
import { _list_workspace } from "../../DAL/workspace";
import { _detail_project, _list_project } from "../../DAL/project";
import { useAppContext } from "../../hooks/AppContext";
import MuiAutoComplete from "../../pages/Components/MuiAutoComplete";
import { useSnackbar } from "notistack";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const useStyles = makeStyles(() => ({
  scrollbarList: {
    position: "relative",
    display: "flex",
    overflowX: "auto",
    scrollbarWidth: "none", // Hide scrollbar for Firefox
    "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar for Chrome, Safari, and Opera
    },
    "&:hover $scrollButtonRight": {
      opacity: 1,
    },
    "&:hover $scrollButtonLeft": {
      opacity: 1,
    },
  },
  listLi: {
    width: "max-content",
    // marginRight: "20px",
    padding: "20px 5px",
    cursor: "pointer",
  },
}));

const Header = (props) => {
  const {
    workspaceArray,
    setWorkspaceArray,
    environmentArray,
    setEnvironmentArray,
    environmentVariable,
    setEnvironmentVariable,
    postmanFile,
    setPostmanFile,
    isLoading,
    setIsLoading,
    addGrid,
    setAddGrid,
    openDialog,
    setOpenDialog,
    openModal,
    setOpenModal,
    currentEnvironmentSelected,
    setCurrentEnvironmentSelected,
    selectedEnvironment,
    setSelectedEnvironment,
    headerArray,
  } = useAppContext();

  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);

  const [anchorElProfileMenu, setAnchorElProfileMenu] = useState(null);

  const [anchorElNotification, setAnchorElNotification] = useState(null);

  const [openEdit, setOpenEdit] = useState(false);

  const [newWorkspace, setNewWorkspace] = useState("");

  const [editWorkspace, setEditWorkspace] = useState({});

  const [workspace, setWorkspace] = useState();

  const [workspaceID, setWorkspaceID] = useState();

  const [projects, setProjects] = useState();

  const [environmentDropdownValue, setEnvironmentDropdownValue] = useState([
    { environment: "Development", url: "" },
    { environment: "Staging", url: "" },
    { environment: "Production", url: "" },
  ]);

  const [openDelete, setOpenDelete] = useState(false);

  const [anchorElpopover, setAnchorElpopover] = useState(null);

  const [linearProgress, setLinearProgress] = useState(false);

  const { project_id } = useParams();
  const location = useLocation();

  let localWorkspace;
  if (localStorage.getItem("workspace")) {
    localWorkspace = JSON.parse(localStorage.getItem("workspace"));
  }

  // ------------------------------------------ //

  const handleClickPopover = (event) => {
    setAnchorElpopover(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorElpopover(null);
  };
  const openPopover = Boolean(anchorElpopover);
  const id = openPopover ? "simple-popover" : undefined;
  const defaultProps = {
    options: selectedEnvironment,
    getOptionLabel: (option) => option?.selectedEnvironment,
  };

  // profile menu
  const handleOpenProfileMenu = (event) => {
    setAnchorElProfileMenu(event.currentTarget);
  };
  const handleCloseProfileMenu = () => {
    setAnchorElProfileMenu(null);
  };

  const openProfileMenu = Boolean(anchorElProfileMenu);
  // ---------------

  // notification menu
  // const handleClickNotification = (event) => {
  //   setAnchorElNotification(event.currentTarget);
  // };
  // const handleCloseNotification = () => {
  //   setAnchorElNotification(null);
  // };

  const openNotification = Boolean(anchorElNotification);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectWorkspace = (workspaceObj) => {
    localStorage.setItem("workspace", JSON.stringify(workspaceObj));
    const tempWorkspaceArr = localStorage.getItem("recentWorkspace")
      ? JSON.parse(localStorage.getItem("recentWorkspace"))
      : [];
    tempWorkspaceArr.push(workspaceObj);
    localStorage.setItem("recentworkspace", JSON.stringify(tempWorkspaceArr));
    setWorkspace(workspaceObj);
    projectList(workspaceObj);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // --------------

  // workspace menu
  const handleClickOpen = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  // Mui Modal for add workspace
  const handleOpenModal = () => setOpenModal(true);
  // --------------

  // Mui Modal for edit workspace
  const handleOpenEditModal = (val) => {
    setEditWorkspace(val);
    setOpenEdit(true);
  };
  // ---------------

  // Mui Modal for delete workspace

  const handleClickOpenDelete = (id) => {
    setWorkspaceID(id);
    setOpenDelete(true);
  };
  // ---------------

  // const formatFileData = () => {
  //   // Split the file content into lines
  //   let lines = [];
  //   if (postmanFile) {
  //     lines = postmanFile.split("\n");
  //   }

  //   // Render each line as a list item
  //   setOutput(lines.map((line, index) => <li key={index}>{line}</li>));
  // };

  const handleChangeWorkspace = (e) => {
    e.preventDefault();
    setWorkspace(e.target.value);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    const result = await _user_logout();
    if (result.code == 200) {
      navigate("/login", { replace: true });
      localStorage.clear();
      setPostmanFile("");
    } else {
      console.log(`Error: ${result.code}`);
    }
  };

  const workspaceList = async () => {
    const result = await _list_workspace();
    if (result.code == 200) {
      setWorkspaceArray(result.workspace_list);
      if (result.workspace_list.length > 0) {
        setWorkspace(result.workspace_list[0]);
        const firstWorkspaceID = result?.workspace_list
          .filter((val, index) => index === 0)
          .map((val) => val)[0];

        projectList(firstWorkspaceID);
      } else {
        setWorkspace({});
      }
    } else {
      console.log(`Error: ${result.code}`);
    }
  };

  useEffect(() => {
    workspaceList();
  }, []);

  const handleOpenProject = async (project_id) => {
    console.log(project_id,"ueiwyruyfuiwhbcdsibcisu")
    setIsLoading(true);
    setPostmanFile(null);
    localStorage.setItem("projectID", JSON.stringify(project_id));
    const result = await _detail_project(project_id);
    if (result.code == 200) {
      console.log(result,"ueiwyruyfuiwhbcdsibcisu")
      setPostmanFile(result.project);
navigate(`project/${result.project._id}`)
      setSelectedEnvironment(
        result.project.environment.map((env) => {
          return { ...env, label: env.environment, value: env };
        })
      );
      setCurrentEnvironmentSelected(null);
      setIsLoading(false);
      const baseUrl = result.project.environment.map((url) => {
        return url.url;
      });

      setAddGrid((prev) => {
        let project = prev[0];
        project = { ...project, url: baseUrl[0] };
        return [project];
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const projectList = async (workspaceObj) => {
    setLinearProgress(true);
    const result = await _list_project(workspaceObj._id);
    if (result.code == 200) {
      setProjects(result.projects);
      setLinearProgress(false);
    } else {
      console.log(`Error: ${result.code}`);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("workspace")) {
      const workspace = JSON.parse(localStorage.getItem("workspace"));
      projectList(workspace);
    }
    if (localStorage.getItem("projectID")) {
      const project_id = JSON.parse(localStorage.getItem("projectID"));
      handleOpenProject(project_id);
    }
  }, []);
  return (
    <>
      <header>
        <div className="container-fluid py-1" id="header">
          <div className="px-3">
            <div className="header">
              <div className="header-wrapper d-flex align-items-center">
                <Button
                  className={`me-2 ${
                    location.pathname == "/home" ? "btn-selected" : ""
                  }`}
                >
                  <Link
                    className={`text-decoration-none`}
                    style={{ color: "#454545" }}
                    to="/"
                  >
                    Home
                  </Link>
                </Button>
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{ color: "#454545" }}
                >
                  {localWorkspace
                    ? localWorkspace.name
                    : workspace?.name
                    ? workspace?.name
                    : "Add New Workspace"}
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {workspaceArray &&
                    workspaceArray?.map((obj, index) => {
                      return (
                        <MenuItem
                          className="mb-2"
                          key={index}
                          onClick={() => handleSelectWorkspace(obj)}
                          disableRipple
                        >
                          <ListItemIcon>
                            <StorefrontIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>{obj.name}</ListItemText>
                          <Button
                            className="workspace-btn ms-4 me-2"
                            variant="contained"
                            size="small"
                            onClick={() => handleOpenEditModal(obj)}
                          >
                            Edit
                          </Button>
                          <Button
                            className="workspace-btn"
                            variant="contained"
                            size="small"
                            // onClick={() => handleDeleteWorkspace(index)}
                            onClick={() => handleClickOpenDelete(obj._id)}
                          >
                            Delete
                          </Button>
                        </MenuItem>
                      );
                    })}
                  <Divider sx={{ my: 0.5 }} />

                  <MenuItem onClick={() => handleClickOpen()} disableRipple>
                    <AddCircleOutlineIcon />
                    Add Workspace
                  </MenuItem>
                </StyledMenu>
              </div>
              <div>
                {/* <Button
                  id="demo-customized-button"
                  className="me-3"
                  disableElevation
                  startIcon={<HelpIcon />}
                  style={{ color: "#454545" }}
                >
                  Help
                </Button>

                <NotificationsNoneIcon
                  style={{ color: "#454545", cursor: "pointer" }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickNotification}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElNotification}
                  open={openNotification}
                  onClose={handleCloseNotification}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      height: "300px",
                      maxHeight: "300px",
                      width: "250px",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center py-2 px-3">
                    <ListItemIcon>
                      <Avatar fontSize="small" sx={{ width: 30, height: 30 }} />
                    </ListItemIcon>
                    <ListItemText>Lorem Ipsum is ...</ListItemText>
                  </div>
                  <Divider />
                  <div className="d-flex justify-content-between align-items-center py-2 px-3">
                    <ListItemIcon>
                      <Avatar fontSize="small" sx={{ width: 30, height: 30 }} />
                    </ListItemIcon>
                    <ListItemText>Lorem Ipsum is ...</ListItemText>
                  </div>
                  <Divider />
                  <div className="d-flex justify-content-between align-items-center py-2 px-3">
                    <ListItemIcon>
                      <Avatar fontSize="small" sx={{ width: 30, height: 30 }} />
                    </ListItemIcon>
                    <ListItemText>Lorem Ipsum is ...</ListItemText>
                  </div>
                  <Divider />
                </Menu>

                <SettingsOutlinedIcon
                  style={{ color: "#454545", cursor: "pointer" }}
                  className="ms-2"
                /> */}

                <PersonOutlineOutlinedIcon
                  onClick={handleOpenProfileMenu}
                  style={{ color: "#454545", cursor: "pointer" }}
                  className="ms-2"
                />
              </div>
              <Menu
                anchorEl={anchorElProfileMenu}
                id="account-menu"
                open={openProfileMenu}
                onClose={handleCloseProfileMenu}
                onClick={handleCloseProfileMenu}
                style={{ cursor: "pointer" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={() => navigate("user-profile")}>
                  <Avatar /> Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={(e) => handleLogout(e)}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className="px-3">
          <div className="header-navbar d-flex justify-content-between align-items-center py-3">
            {linearProgress ? (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            ) : (
              <Box className={`${classes.scrollbarList} header-navbar-list`}>
                <ul className="project-list d-flex justify-content-start align-items-center list-unstyled mb-0">
                  {projects &&
                    projects.map((project, index) => {
                      return (
                        <li
                          key={index}
                          className={classes.listLi}
                          style={{ fontSize: "14px", cursor: "pointer" }}
                        >
                          <Link
                            className={`text-decoration-none mx-3 px-4 item ${
                              project_id == project._id ? "selected" : ""
                            }`}
                            style={{ fontSize: "14px" }}
                            onClick={() => handleOpenProject(project._id)}
                            to={`project/${project._id}`}
                          >
                            {project.name}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </Box>
            )}

            <div className="d-flex align-items-center">
              <Button
                className="mx-4"
                variant="contained"
                onClick={handleOpenModal}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  width: "max-content",
                }}
              >
                Create Project
              </Button>
              <MuiAutoComplete
                label="Environment"
                value={currentEnvironmentSelected}
                options={selectedEnvironment.map((env) => {
                  return { ...env, label: env.environment };
                })}
                onChange={(val) => setCurrentEnvironmentSelected(val)}
              />
              <AppRegistrationOutlinedIcon onClick={handleClickPopover} />
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorElpopover}
                onClose={handleClosePopover}
                style={{ width: "900px", padding: "10px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="d-flex justify-content-between align-items-center bg-secondary">
                  <Typography sx={{ p: 2, color: "#fff" }}>
                    {postmanFile?.name}
                  </Typography>
                  <Typography sx={{ p: 2, color: "#fff" }}>
                    {postmanFile?.environment?.length == 0 ? "Add" : "Edit"}
                  </Typography>
                </div>
                <div>
                  {postmanFile?.environment?.length > 0 ? (
                    <table
                      className="table table-borderless"
                      style={{ height: "120px" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "110px" }}>
                            Variable
                          </th>
                          <th scope="col">Initial Value</th>
                          <th scope="col">Current Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {postmanFile &&
                          postmanFile?.environment.map((env, index) => {
                            return (
                              <tr key={index}>
                                <td>{env.environment}</td>
                                <td
                                  className="px-2"
                                  style={{
                                    width: "385px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {env.url}
                                </td>
                                <td
                                  className="px-2"
                                  style={{
                                    width: "385px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {env.url}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center py-5">
                      <h6 className="mb-2">No Environment variables</h6>
                      <p>
                        An environment is a set of variables that allow you to
                        switch the context of your requests.
                      </p>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center bg-secondary">
                  <Typography sx={{ p: 2, color: "#fff" }}>Globals</Typography>
                  <Typography sx={{ p: 2, color: "#fff" }}>Edit</Typography>
                </div>
                <div className="table-responsive">
                  {headerArray.length > 0 ? (
                    <table
                      class="table table-borderless"
                      style={{ height: "200px" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "110px" }}>
                            Variable
                          </th>
                          <th scope="col">Initial Value</th>
                          <th scope="col">Current Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>host</td>
                          <td
                            className="px-2"
                            style={{ wordBreak: "break-all" }}
                          >
                            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbl90b2tlbiI6IjgwZDNmMDkwLWJlNWUtMTFlZC04ZTU0LTJmNjhhOWQ0MjA2NiIsImFjY2VzcyI6ImF1dGgiLCJpYXQi
                          </td>
                          <td
                            className="px-2"
                            style={{ wordBreak: "break-all" }}
                          >
                            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbl90b2tlbiI6IjgwZDNmMDkwLWJlNWUtMTFlZC04ZTU0LTJmNjhhOWQ0MjA2NiIsImFjY2VzcyI6ImF1dGgiLCJpYXQi
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center py-5">
                      <h6 className="mb-2">No global variables</h6>
                      <p>
                        Global variables are a set of variables that are always
                        available in a workspace.
                      </p>
                    </div>
                  )}
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </header>

      <WorkspaceDialogs
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        newWorkspace={newWorkspace}
        setNewWorkspace={setNewWorkspace}
        workspaceArray={workspaceArray}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        setWorkspaceArray={setWorkspaceArray}
        editWorkspace={editWorkspace}
        setEditWorkspace={setEditWorkspace}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        workspaceID={workspaceID && workspaceID}
        workspaceList={workspaceList}
        setWorkspace={setWorkspace}
      />
      <NewProjectModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        workspace={workspace}
        environmentDropdownValue={environmentDropdownValue}
        setEnvironmentDropdownValue={setEnvironmentDropdownValue}
        environmentArray={environmentArray}
        setEnvironmentArray={setEnvironmentArray}
        postmanFile={postmanFile}
        setPostmanFile={setPostmanFile}
        handleOpenProject={handleOpenProject}
        handleChangeWorkspace={handleChangeWorkspace}
        setSelectedEnvironment={setSelectedEnvironment}
        selectedEnvironment={selectedEnvironment}
        projectList={projectList}
        addGrid={addGrid}
        setAddGrid={setAddGrid}
      />
      <Outlet />
    </>
  );
};

export default Header;
