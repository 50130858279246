import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  OutlinedInput,
  Button,
  TextField,
  Modal,
  Box,
  IconButton,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Avatar,
} from "@mui/material";
// icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// ----------
import PropTypes from "prop-types";
// SyntaxHighlighter
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { invokeApiForPostman } from "../../../utils/invokeApiForPostman";
import { useAppContext } from "../../../hooks/AppContext";
import { useSnackbar } from "notistack";
// -------------------------------------------------------------------

// mui tab function
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// modal style start
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 10,
  p: 1,
};
// modal style end
// mui table start
const columns = [
  { field: "key", headerName: "Key", width: 70 },
  {
    field: "value",
    headerName: "Value",
    type: "",
    width: 90,
  },
  {
    field: "description",
    headerName: "Description",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
  },
];
// mui table end

const APIComponent = (props) => {
  const { apiData, apiIndex } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [isVisble, setIsVisble] = useState(false);
  const [open, setOpen] = useState(false); //modal
  const handleClose = () => setOpen(false); //-----

  const [value, setValue] = useState(apiData.request.url.raw);
  const [radioInput, setRadioInput] = useState("none");
  const [pathUrl, setPathUrl] = useState("");
  const [response, setResponse] = useState();
  const [rawData, setRawData] = useState("");
  const [formdataArray, setFormdataArray] = useState([]);
  const [paramsArray, setParamsArray] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectedCheckboxHeader, setSelectedCheckboxHeader] = useState([]);
  const [selectedCheckboxParams, setSelectedCheckboxParams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [valueOutput, setValueOutput] = useState("");
  const [inputWithoutKey, setInputWithoutKey] = useState("");
  const [showList, setShowList] = useState(false);
  const [getIndex, setGetIndex] = useState(null);

  const {
    currentEnvironmentSelected,
    selectedEnvironment,
    headerArray,
    setHeaderArray,
  } = useAppContext();

  // -------Api Function--------
  const handleExecuteApi = async (
    apiData,
    apiIndex,
    formdataArray,
    headerArray
  ) => {
    console.log(formdataArray, "data--------2");
    console.log(rawData, "data--------4");
    // if (currentEnvironmentSelected || valueOutput) {
    // } else if (!currentEnvironmentSelected && !valueOutput) {
    //   enqueueSnackbar("Environment not found!", { variant: "error" });
    //   return;
    // }

    let headers = {};
    headerArray.map((val, index) => {
      let newIndex = selectedCheckboxHeader.indexOf(index);
      if (newIndex !== -1) {
        headers = {
          ...headers,
          [val.key]: val.value,
        };
      }
    });
    if (formdataArray.length > 0 && radioInput == "formdata") {
      setIsLoading(true);
      const formData = new FormData();
      formdataArray.map((val, index) => {
        let newIndex = selectedCheckbox.indexOf(index);
        if (newIndex !== -1) {
          return formData.append(`${val.key}`, val.value ? val.value : val.src);
        }
      });
      headers = { ...headers, "Content-Type": "multipart/form-data" };
      console.log(inputWithoutKey, "ksajdlkasjdlkasjd");
      let path;
          if (valueOutput && inputWithoutKey) {
            path = valueOutput + inputWithoutKey;
            console.log("1st----------------")
          } else if (currentEnvironmentSelected?.url && pathUrl) {
            path = currentEnvironmentSelected.url + pathUrl;
            console.log("2nd----------------")
          } else if (pathUrl) {
            path = pathUrl;
            console.log("3rd----------------")
          }
      const reqObj = {
        path:path,
        // path: valueOutput
        //   ? valueOutput + inputWithoutKey
        //   : currentEnvironmentSelected.url + pathUrl,
        method: apiData.request.method,
        headers,
        postData: formData,
      };
      const result = await invokeApiForPostman(reqObj);
      if (result.code == 200) {
        setResponse(result);
        setIsLoading(false);
        return;
      } else {
        // enqueueSnackbar(result.message, { variant: "error" });
        setResponse(result);
        setIsLoading(false);
        return;
      }
    }
    if (radioInput == "raw" || radioInput == "none") {
      if (apiData.request.method == "POST" || apiData.request.method == "PUT") {
        console.log("alksdjlsajdlka====================================================")
        try {
          setIsLoading(true);
          let path;
          if (valueOutput && inputWithoutKey) {
            path = valueOutput + inputWithoutKey;
            console.log("1st----------------")
          } else if (currentEnvironmentSelected?.url && pathUrl) {
            path = currentEnvironmentSelected.url + pathUrl;
            console.log("2nd----------------")
          } else if (pathUrl) {
            path = pathUrl;
            console.log("3rd----------------")
          }
          const reqObj = {
            // path: valueOutput
            //   ? valueOutput + inputWithoutKey
            //   : currentEnvironmentSelected.url + pathUrl,
            path: path,
            method: apiData.request.method,
            headers,
            postData: JSON.parse(rawData),
          };

          const result = await invokeApiForPostman(reqObj);
          if (result.code == 200) {
            setResponse(result);
            setIsLoading(false);
          } else {
            // enqueueSnackbar(result.message, { variant: "error" });
            setResponse(result);
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error,"kkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
          setIsLoading(false);
        }
      } else {
        try {
          setIsLoading(true);
          console.log(inputWithoutKey, "ksajdlkasjdlkasjd");
          let path;
          if (valueOutput && inputWithoutKey) {
            path = valueOutput + inputWithoutKey;
            console.log("1st----------------")
          } else if (currentEnvironmentSelected?.url && pathUrl) {
            path = currentEnvironmentSelected.url + pathUrl;
            console.log("2nd----------------")
          } else if (pathUrl) {
            path = pathUrl;
            console.log("3rd----------------")
          }
          const reqObj = {
            path:path,
            // path: valueOutput
            //   ? valueOutput + inputWithoutKey
            //   : currentEnvironmentSelected.url + pathUrl,
            method: apiData.request.method,
            headers,
          };

          const result = await invokeApiForPostman(reqObj);
          if (result.code == 200) {
            setResponse(result);
            setIsLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    }
  };
  // -------Api Function End--------

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleGetRadioInput = (body) => {
    if (body) {
      if (body?.formdata) {
        setRadioInput("formdata");
      } else if (body?.raw) {
        setRadioInput("raw");
      } else {
        setRadioInput("none");
      }
    }
    return radioInput;
  };
  // handle change for formdata
  const handleChangeFormdataValue = (e, index) => {
    if (e.target.type === "file") {
      let name = e.target.name;
      let value = e.target.files[0];
      setFormdataArray((prevState) => {
        const updatedList = [...prevState];
        updatedList[index] = { ...updatedList[index], [name]: value };
        return updatedList;
      });
    } else {
      const { name, value } = e.target;
      setFormdataArray((prevState) => {
        const updatedList = [...prevState];
        updatedList[index] = { ...updatedList[index], [name]: value };
        return updatedList;
      });
    }
  };
  // handle change for header
  const handleChangeHeaderValue = (e, index) => {
    const { name, value } = e.target;
    setHeaderArray((prevState) => {
      const updatedList = [...prevState];
      updatedList[index] = { ...updatedList[index], [name]: value };
      return updatedList;
    });
  };
  // handle change for params
  const handleChangeParamsValue = (e, index) => {
    const { name, value } = e.target;
    setParamsArray((prevState) => {
      const updatedList = [...prevState];
      updatedList[index] = { ...updatedList[index], [name]: value };
      return updatedList;
    });
  };

  // handle to remove header row
  const handleRemoveRow = (row, index) => {
    if (headerArray?.length > 1) {
      const newArray = headerArray.filter((value, valueIndex) => {
        if (valueIndex != index) {
          return value;
        }
      });
      setHeaderArray(newArray);
    }
    setSelectedCheckboxHeader((prev) => prev.filter((val) => val !== index));
  };
  // handle to remove formdata row
  const handleRemoveRowForFormdata = (row, index) => {
    if (formdataArray?.length > 1) {
      const newArray = formdataArray.filter((value, valueIndex) => {
        if (valueIndex != index) {
          return value;
        }
      });
      setFormdataArray(newArray);
    }
    setSelectedCheckbox((prev) => prev.filter((val) => val !== index));
  };
  // handle to remove params row
  const handleRemoveRowForParams = (row, index) => {
    if (paramsArray?.length > 1) {
      const newArray = paramsArray.filter((value, valueIndex) => {
        if (valueIndex != index) {
          return value;
        }
      });
      setParamsArray(newArray);
    }
    setSelectedCheckboxParams((prev) => prev.filter((val) => val !== index));
  };

  const handleChangeForRadioInput = (event) => {
    setRadioInput(event.target.value);
  };

  const handleSetPath = (subFolder, formdata) => {
    if (formdata) {
      formdata.map((val, index) =>
        setSelectedCheckbox((prev) => [...prev, index])
      );
    }
    if (apiData?.request?.body?.formdata) {
      let newFormdataArray = apiData?.request?.body?.formdata;
      setFormdataArray([
        ...newFormdataArray,
        { key: "", type: "text", value: "" },
      ]);
    } else {
      formdataArray.push([{ key: "", type: "text", value: "" }]);
    }

    if (apiData?.request?.url?.query) {
      let paramsData = apiData?.request?.url?.query;
      setParamsArray([...paramsData, { key: "", value: "" }]);
    } else {
      paramsArray.push({ key: "", value: "" });
    }

    if (subFolder?.request?.url?.raw) {
      setPathUrl(subFolder?.request?.url?.raw.split("}")[2]);
    } else {
      setPathUrl(subFolder?.request?.url.split("}")[2]);
    }
    setRawData(subFolder?.request.body?.raw);
  };

  const changeHandler = (e, index, obj) => {
    e.preventDefault();
    const { value } = e.target;
    console.log(value, "aaaaaaaaaaaaaaaaaaaaaaa");
    setFormdataArray((prevState) => {
      const newDataArray = [...prevState];
      newDataArray[index].type = value;
      return newDataArray;
    });
  };

  const handleChange = () => {
    setIsVisble(!isVisble);
  };

  const handleClick = (e, index, val) => {
    const newIndex = selectedCheckbox.indexOf(index);
    console.log(newIndex, "newIndexnewIndex");
    if (newIndex >= 0) {
      setSelectedCheckbox((prev) => prev.filter((value) => value !== index));
      const newArray = formdataArray.filter(
        (value, vindex) => vindex !== index
      );
      console.log(newArray, "newArray");
    } else {
      setSelectedCheckbox((prev) => [...prev, index]);
    }
  };
  const handleHeaderCheckbox = (e, index, val) => {
    const newIndex = selectedCheckboxHeader.indexOf(index);
    console.log(newIndex, "newIndexnewIndex");
    if (newIndex >= 0) {
      setSelectedCheckboxHeader((prev) =>
        prev.filter((value) => value !== index)
      );
      const newArray = headerArray.filter((value, vindex) => vindex !== index);
      console.log(newArray, "newArray");
    } else {
      setSelectedCheckboxHeader((prev) => [...prev, index]);
    }
  };
  const handleParamsCheckbox = (e, index, val) => {
    const newIndex = selectedCheckboxParams.indexOf(index);
    console.log(newIndex, "newIndexnewIndex");
    if (newIndex >= 0) {
      setSelectedCheckboxParams((prev) =>
        prev.filter((value) => value !== index)
      );
      const newArray = paramsArray.filter((value, vindex) => vindex !== index);
      console.log(newArray, "newArray");
    } else {
      setSelectedCheckboxParams((prev) => [...prev, index]);
    }
  };

  const onKeypressEvent = (e, index, obj) => {
    if (obj.length - 1 == index) {
      let length = obj.length - 1;
      console.log(length, index, "==equal==");
      headerArray.push({ key: "", type: "text", value: "" });
    }
  };
  const onKeypressEventForFormdata = (e, index, obj) => {
    if (obj.length - 1 == index) {
      let length = obj.length - 1;
      console.log(length, index, "==equal==");
      formdataArray.push({ key: "", type: "text", value: "" });
    }
  };
  const onKeypressEventForParams = (e, index, obj) => {
    if (obj.length - 1 == index) {
      let length = obj.length - 1;
      console.log(length, index, "==equal==");
      paramsArray.push({ key: "", value: "" });
    }
  };

  const readBracesValue = (val) => {};

  const handleSelectFromList = useCallback(
    (env) => {
      const selectedEnv = `{{${env}}}${pathUrl.split("{")[1]}`;
      console.log(selectedEnv, "kalsdlkasdklasd");
      if (selectedEnv) {
        setPathUrl(selectedEnv);
      }
      const regex = /\{\{([^}]+)\}\}/g;
      const matches = [...selectedEnv.matchAll(regex)];
      const variables = matches.map((match) => match[1]);

      if (variables) {
        const foundItem = selectedEnvironment.find(
          (item) => item.environment == variables[0]
        );
        console.log(foundItem, "found----");
        if (foundItem) {
          setValueOutput(foundItem.url);
          console.log(foundItem.url, "founditem");
        }
      }
      setShowList(false);
    },
    [pathUrl, selectedEnvironment]
  );

  const lookupValue = useCallback(
    (e) => {
      if (e.target.value[0] === "{") {
        setShowList(true);
      } else {
        setShowList(false);
      }
      if (pathUrl.includes("}}")) {
        setShowList(false);
      }
      const regex = /\{\{([^}]+)\}\}/g;
      const matches = [...pathUrl.matchAll(regex)];
      const variables = matches.map((match) => match[1]);
      console.log(variables, "variables----");
      // setInputWithoutKey(e.target.value.split("}")[2]);
      if (variables) {
        const foundItem = selectedEnvironment.find(
          (item) => item.environment === variables[0]
        );
        console.log(foundItem, "found----");
        if (foundItem) {
          setValueOutput(foundItem.url);
          console.log(foundItem.url, "founditem");
        } else {
          setValueOutput("Key not found");
        }
      }
    },
    [pathUrl, selectedEnvironment]
  );

  useEffect(() => {
    if (apiData?.request?.header) {
      let tokenData = apiData?.request?.header;
      setHeaderArray([...tokenData, { key: "", type: "text", value: "" }]);
    }
  }, []);
  useEffect(() => {
    console.log(selectedCheckboxParams, "cascacacsa");
  }, [selectedCheckboxParams]);
  useEffect(() => {
    console.log(pathUrl, "text field=========");
  }, [pathUrl]);
  return (
    <Accordion
      expanded={isVisble}
      onChange={handleChange}
      key={apiIndex}
      id={apiIndex}
    >
      <AccordionSummary
        className="summary"
        expandIcon={<ExpandMoreIcon />}
        onClick={() => handleSetPath(apiData, apiData.request.body?.formdata)}
      >
        <div className="d-flex align-items-center">
          <Typography
            className={apiData.request.method}
            style={{
              width: "70px",
              textAlign: "center",
              marginRight: "10px",
              borderRadius: "10px",
            }}
          >
            <strong style={{ fontSize: "12px" }}>
              {apiData.request.method}
            </strong>
          </Typography>
          <Typography>
            <strong>{apiData.name}</strong>
          </Typography>
        </div>
      </AccordionSummary>
      {isVisble && (
        <AccordionDetails>
          <div className="d-flex justify-content-between align-items-center">
            <div className="w-100">
              <TextField
                id="standard-basic"
                placeholder="Api Path"
                variant="standard"
                value={pathUrl}
                style={{ width: "100%", maxWidth: "500px" }}
                onChange={(e) => {
                  setInputWithoutKey(e.target.value.split("{")[1]);
                  lookupValue(e);
                  setPathUrl(e.target.value);
                }}
              />
              {showList && (
                <div
                  className="row"
                  style={{
                    maxWidth: "350px",
                    position: "absolute",
                    background: "#eeeeee",
                    border: "2px solid #e3e3e3",
                    borderRadius: "5px",
                    zIndex: "1",
                    left: "20px",
                    right: "0",
                  }}
                >
                  <div className="col-md-4 border-end env-left-list">
                    <ul className="env-list d-flex flex-column list-unstyled mb-0 py-2">
                      {selectedEnvironment.map((env, envIndex) => {
                        return (
                          <li
                            className="d-flex align-items-center w-100"
                            onClick={() =>
                              handleSelectFromList(env.environment)
                            }
                            style={{ fontSize: "12px", cursor: "pointer" }}
                            onMouseOver={() => setGetIndex(envIndex)}
                          >
                            <Avatar
                              className="me-1"
                              sx={{ width: 18, height: 18, fontSize: 12 }}
                              variant="square"
                            >
                              {env.environment[0]}
                            </Avatar>
                            {env.environment}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="col-md-8 py-2">
                    {selectedEnvironment.map((env, envIndex) =>
                      getIndex === envIndex ? (
                        <div
                          className="env-right-list"
                          style={{ overflowX: "hidden" }}
                          key={envIndex}
                        >
                          <div className="d-flex">
                            <p className="para-title">Environment:</p>
                            <p>{env.environment}</p>
                          </div>
                          <div className="d-flex">
                            <p className="para-title">Url:</p>
                            <p style={{overflowWrap:"anywhere"}}>{env.url}</p>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              )}
            </div>

            <div>
              {/* {subFolder.response?.map((check_body) => {
              return (
                check_body?.body && (
                  <Button
                    variant="contained"
                    size="small"
                    className="me-2"
                    style={{
                      lineHeight: "unset",
                      padding: "4px 10px 2px",
                    }}
                    onClick={() => handleOpen()}
                  >
                    {" "}
                    Show Example
                  </Button>
                )
              );
            })} */}
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {
                  handleExecuteApi(
                    apiData,
                    apiIndex,
                    formdataArray,
                    headerArray
                  );
                }}
                style={{
                  lineHeight: "unset",
                  padding: "4px 10px 2px",
                  width: "120px",
                }}
              >
                Try it out
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box className="d-flex justify-content-end">
                    <IconButton
                      onClick={() => handleClose()}
                      aria-label="delete"
                    >
                      <CloseIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                  </Box>
                  {apiData?.response.map((response_body, index) => {
                    return (
                      response_body.body && (
                        <TextField
                          id="outlined-multiline-static"
                          className="textoutput"
                          fullWidth
                          multiline
                          rows={10}
                          value={response_body.body}
                        />
                      )
                    );
                  })}
                </Box>
              </Modal>
            </div>
          </div>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                onClick={
                  () => handleGetRadioInput(apiData.request.body) //send formdata object from here
                }
                className=""
                aria-label="basic tabs example"
              >
                <Tab
                  label="Params"
                  {...a11yProps(0)}
                  className="d-flex flex-row-reverse"
                  icon={
                    apiData?.request?.url?.query && (
                      <FiberManualRecordIcon
                        className="mui-icon"
                        fontSize="15px"
                      />
                    )
                  }
                />
                <Tab
                  label="Body"
                  {...a11yProps(1)}
                  className="d-flex flex-row-reverse"
                  icon={
                    (apiData.request.body?.raw ||
                      apiData.request.body?.formdata) && (
                      <FiberManualRecordIcon
                        className="mui-icon"
                        fontSize="15px"
                      />
                    )
                  }
                />
                <Tab
                  label="Header"
                  {...a11yProps(2)}
                  className="d-flex flex-row-reverse"
                  icon={
                    apiData?.request?.header.length > 0 ? (
                      <FiberManualRecordIcon
                        className="mui-icon"
                        fontSize="15px"
                      />
                    ) : (
                      ""
                    )
                  }
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="table-responsive">
                <table
                  className="table table-bordered table-sm mt-3 params-table"
                  style={{ borderColor: "#ccc !important" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="align-middle px-2"
                        style={{ width: "40px", maxWidth: "40px" }}
                      ></th>
                      <th style={{ width: "200px" }}>Key</th>
                      <th>Value</th>
                      <th style={{ width: "30px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {paramsArray?.map((param, index, obj) => (
                      <>
                        <tr key={index}>
                          <td className="first-col align-middle">
                            <Checkbox
                              checked={
                                selectedCheckboxParams.indexOf(index) >= 0
                              }
                              // name={index}
                              onChange={(e) => handleParamsCheckbox(e, index)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </td>
                          <td>
                            <input
                              className="border-0 w-100"
                              name="key"
                              value={param.key}
                              type="text"
                              onChange={(e) =>
                                handleChangeParamsValue(e, index)
                              }
                              onKeyPress={(e) =>
                                onKeypressEventForParams(e, index, obj)
                              }
                              placeholder="Enter Key"
                              style={{ outline: "none" }}
                            />
                          </td>
                          <td>
                            <input
                              className="border-0 w-100"
                              name="value"
                              value={param.key.value}
                              type="text"
                              multiline
                              rows={3}
                              onChange={(e) =>
                                handleChangeParamsValue(e, index)
                              }
                              placeholder="Enter Value"
                              style={{ outline: "none" }}
                            />
                          </td>
                          <td>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleRemoveRowForParams(param, index)
                              }
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  className="d-flex align-items-center"
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="controlled-radio-buttons-group"
                  row
                  value={radioInput}
                  onChange={handleChangeForRadioInput}
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="None"
                  />
                  <FormControlLabel
                    value="formdata"
                    control={<Radio />}
                    label="Formdata"
                  />

                  <FormControlLabel
                    value="raw"
                    control={<Radio />}
                    label="Raw"
                  />
                </RadioGroup>
              </FormControl>
              {radioInput == "none" ? (
                ""
              ) : radioInput == "formdata" ? (
                <>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-sm mt-3 formdata-table"
                      style={{ borderColor: "#ccc !important" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className="align-middle px-2"
                            style={{ width: "40px", maxWidth: "40px" }}
                          ></th>
                          <th
                            className="align-middle px-2"
                            style={{ width: "300px", maxWidth: "300px" }}
                          >
                            Key
                          </th>
                          <th
                            className="align-middle px-2"
                            style={{ width: "300px", maxWidth: "300px" }}
                          >
                            Value
                          </th>
                          <th className="align-middle px-2">Description</th>
                          <th className="align-middle px-2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {formdataArray.map((val, index, obj) => (
                          <tr key={index}>
                            <td className="first-col align-middle">
                              <Checkbox
                                checked={selectedCheckbox.indexOf(index) >= 0}
                                // name={index}
                                onChange={(e) => handleClick(e, index, obj)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </td>
                            <td className="align-middle">
                              <div className="d-flex justify-content-between align-items-center">
                                <input
                                  className="border-0 w-100 textfield-without-outline"
                                  name="key"
                                  value={val.key}
                                  type="text"
                                  onChange={(e) =>
                                    handleChangeFormdataValue(e, index)
                                  }
                                  onKeyPress={(e) =>
                                    onKeypressEventForFormdata(e, index, obj)
                                  }
                                  placeholder="Enter Key"
                                  style={{ outline: "none" }}
                                />

                                <div className="d-flex justify-content-between align-items-center">
                                  <FormControl
                                    className="first-col-select"
                                    style={{
                                      paddingTop: "0",
                                      paddingBottom: "0",
                                    }}
                                  >
                                    <Select
                                      className="table-select"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      name={"fileandtext"}
                                      value={val.type}
                                      size="small"
                                      onChange={(e) =>
                                        changeHandler(e, index, obj)
                                      }
                                    >
                                      <MenuItem value={"text"}>Text</MenuItem>
                                      <MenuItem value={"file"}>File</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </td>
                            {console.log(val.type, "val.typeval.typeval.type")}
                            <td className="align-middle ">
                              {val.type == "file" ? (
                                <input
                                  type="file"
                                  accept="image/audio"
                                  name="src"
                                  onChange={(e) =>
                                    handleChangeFormdataValue(e, index)
                                  }
                                />
                              ) : (
                                <input
                                  className="border-0 w-100 textfield-without-outline"
                                  value={val.value}
                                  name="value"
                                  type="text"
                                  onChange={(e) =>
                                    handleChangeFormdataValue(e, index)
                                  }
                                  style={{ outline: "none" }}
                                  placeholder="Enter value"
                                />
                              )}
                            </td>
                            <td className="align-middle">
                              <textarea
                                className="border-0 w-100 textfield-without-outline"
                                name="type"
                                value={val.description}
                                type="text"
                                rows={1}
                                onChange={(e) =>
                                  handleChangeFormdataValue(e, index)
                                }
                                style={{ outline: "none" }}
                                placeholder="description"
                              ></textarea>
                            </td>
                            <td>
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleRemoveRowForFormdata(val, index)
                                }
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : radioInput == "raw" ? (
                <>
                  <TextField
                    id="outlined-multiline-static"
                    className="textoutput"
                    style={{ color: "#fff" }}
                    fullWidth
                    multiline
                    rows={7}
                    value={rawData}
                    onChange={(e) => setRawData(e.target.value)}
                  />
                </>
              ) : (
                ""
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="table-responsive">
                <table
                  className="table table-bordered table-sm mt-3 header-table"
                  style={{ borderColor: "#ccc !important" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="align-middle px-2"
                        style={{ width: "40px", maxWidth: "40px" }}
                      ></th>
                      <th style={{ width: "200px" }}>Key</th>
                      <th>Value</th>
                      <th style={{ width: "30px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {headerArray.map((val, index, obj) => (
                      <>
                        <tr key={index}>
                          <td className="first-col align-middle">
                            <Checkbox
                              checked={
                                selectedCheckboxHeader.indexOf(index) >= 0
                              }
                              // name={index}
                              onChange={(e) => handleHeaderCheckbox(e, index)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </td>
                          <td>
                            <input
                              className="border-0 w-100"
                              name="key"
                              value={val.key}
                              type="text"
                              onChange={(e) =>
                                handleChangeHeaderValue(e, index)
                              }
                              onKeyPress={(e) => onKeypressEvent(e, index, obj)}
                              placeholder="Enter Key"
                              style={{ outline: "none" }}
                            />
                          </td>
                          <td>
                            <input
                              className="border-0 w-100"
                              name="value"
                              value={val.value}
                              type="text"
                              multiline
                              rows={3}
                              onChange={(e) =>
                                handleChangeHeaderValue(e, index)
                              }
                              placeholder="Enter Value"
                              style={{ outline: "none" }}
                            />
                          </td>
                          <td>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleRemoveRow(val, index)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Box>
          <div className="syntax-highlighter" style={{ margin: "0 25px" }}>
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "430px" }}
              >
                <CircularProgress />
              </div>
            ) : (
              response && (
                <div>
                  <h6 className="border-bottom-heading">Response</h6>
                  <SyntaxHighlighter
                    className="textoutput"
                    language="json"
                    style={nightOwl}
                    showLineNumbers
                    wrapLines={true}
                    wrapLongLines={true}
                    // height={300}
                  >
                    {JSON.stringify(response, undefined, 2)}
                  </SyntaxHighlighter>
                </div>
              )
            )}
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default APIComponent;
