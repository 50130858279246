import "./App.css";

import React, { useState } from 'react';
// routes
import Router from "./routes";
// theme, Mui
import { IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//libs
import { SnackbarProvider } from 'notistack';


function App() {

  const notiStackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notiStackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarProvider
        hideIconVariant
        ref={notiStackRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
        maxSnack={3}
        autoHideDuration={3000}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon htmlColor='white' />
          </IconButton>
        )}
      >
        <Router />
      </SnackbarProvider>
    </>
  );
}

export default App;
