import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
// icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Link, useNavigate } from "react-router-dom";
import { _user_login } from "../../DAL/auth";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../hooks/AppContext";

const Login = () => {

  const {setIsLoading} = useAppContext();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  // ------------

  // password field function
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const reqObj = { email: email, password: password, type: 1 };

    const result = await _user_login(reqObj);
    if (result.code === 200) {
      localStorage.setItem("token", result.token);
      setEmail("");
      setPassword("");
      setIsLoading(true);
      navigate("/home");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      console.log(`Error: ${result.code}`);
    }
  };

  return (
    <div className="screen-size py-5">
      <div className="container">
        <div style={{ borderRadius: "10px", boxShadow: "#dadada 0px 0px 5px" }}>
          <div className="row">
            <div className="col-md-7">
              <div className="postman-login d-flex flex-column align-items-center justify-content-center px-5 text-center">
                <h1 className="mb-5">Login to Your Account</h1>

                <form
                  onSubmit={(e) => handleLogin(e)}
                  noValidate={false}
                  autoComplete="off"
                >
                  <TextField
                    className="email-field mb-2"
                    id="outlined-controlled"
                    placeholder="Email"
                    type="email"
                    size="small"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    required
                  />
                  <FormControl
                    className="password-field"
                    // sx={{ m: 1 }}
                    variant="outlined"
                    size="small"
                    required
                  >
                    {/* <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel> */}
                    <OutlinedInput
                      id="outlined-adornment-password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            //   aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      // label="Password"
                    />
                    <div className="d-flex justify-content-end">
                      <Link to="/forget-password">Forgot Password?</Link>
                    </div>
                  </FormControl>
                  <Button
                    className="mt-4"
                    size="small"
                    variant="contained"
                    style={{
                      borderRadius: "30px",
                      color: "#fff",
                      padding: "10px 70px",
                    }}
                    type="submit"
                  >
                    Sign In
                  </Button>
                </form>
                <p className="mt-3">Or</p>
                <div className="d-flex justify-content-center align-items-center">
                  <p className="px-2 mb-1">Connect With Social Media</p>{" "}
                  <a
                    href="#"
                    style={{
                      marginRight: "3px",
                      padding: "5px 12px",
                      background: "#f0f0f0",
                    }}
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                    {/* <FacebookOutlinedIcon /> */}
                  </a>
                  <a
                    href="#"
                    style={{
                      marginRight: "3px",
                      padding: "5px 10px",
                      background: "#f0f0f0",
                    }}
                  >
                    <i className="fa-brands fa-google"></i>
                    {/* <GoogleIcon /> */}
                  </a>
                  <a
                    href="#"
                    style={{
                      padding: "5px 11px",
                      background: "#f0f0f0",
                    }}
                  >
                    <i className="fa-brands fa-linkedin-in"></i>
                    {/* <LinkedInIcon /> */}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <div className="postman-login-col-right d-flex flex-column justify-content-center align-items-center">
                <h1 className="text-white text-center">New Here</h1>
                <h6 className="text-white text-center">
                  Signup and discover new oppertunities!
                </h6>
                <Button
                  className="mt-4"
                  style={{
                    background: "#ffffff",
                    borderRadius: "30px",
                    padding: "10px 70px",
                    color: "#000",
                  }}
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
