import React, { useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
// icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { _user_signup } from "../../DAL/auth";
import { useSnackbar } from "notistack";
import { useAppContext } from "../../hooks/AppContext";

const Signup = () => {
  const { setIsLoading } = useAppContext();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // ------------

  // password field function
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // -------

  const handleSignup = async (e) => {
    e.preventDefault();
    const reqObj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };
    const result = await _user_signup(reqObj);
    if (result.code === 200) {
      localStorage.setItem("token", result.customer.token);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setIsLoading(true);
      navigate("/home");
    } else {
      console.log(`Error: ${result.code}`);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  return (
    <div className="screen-size py-5">
      <div className="container">
        <div style={{ borderRadius: "10px", boxShadow: "#dadada 0px 0px 5px" }}>
          <div className="row">
            <div className="col-md-7">
              <div className="postman-signup d-flex flex-column align-items-center justify-content-center px-5 text-center">
                <h1 className="mb-4">Signup</h1>
                <p className="mb-5">Signup and discover new oppertunities!</p>

                <form
                  onSubmit={(e) => handleSignup(e)}
                  noValidate={false}
                  autoComplete="off"
                >
                  <TextField
                    className="email-field mb-2"
                    id="outlined-controlled-fname"
                    placeholder="First Name"
                    size="small"
                    type="text"
                    value={firstName}
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                    required
                  />

                  <TextField
                    className="email-field mb-2"
                    id="outlined-controlled-lname"
                    placeholder="Last Name"
                    type="text"
                    size="small"
                    value={lastName}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                    required
                  />

                  <TextField
                    className="email-field mb-2"
                    id="outlined-controlled-email"
                    placeholder="Email"
                    type="email"
                    size="small"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    required
                  />

                  <FormControl
                    className="password-field"
                    id="outlined-controlled-password"
                    variant="outlined"
                    size="small"
                    required
                  >
                    <OutlinedInput
                      id="outlined-adornment-password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            //   aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      // label="Password"
                    />
                  </FormControl>
                  <Button
                    className="mt-4"
                    size="small"
                    variant="contained"
                    style={{
                      borderRadius: "30px",
                      color: "#fff",
                      padding: "10px 70px",
                    }}
                    type="submit"
                  >
                    Sign Up
                  </Button>
                </form>
              </div>
            </div>

            <div className="col-md-5">
              <div className="postman-login-col-right d-flex flex-column justify-content-center align-items-center">
                <h1 className="text-white text-center">Already Have Account</h1>
                <h6 className="text-white text-center">
                  Login and discover new oppertunities!
                </h6>
                <Button
                  className="mt-4"
                  style={{
                    background: "#ffffff",
                    borderRadius: "30px",
                    color: "#000",
                    padding: "10px 70px",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Sign In
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
