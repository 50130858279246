import * as React from "react";
import { useState, useEffect } from "react";
import { _list_workspace } from "../../DAL/workspace";
import { _detail_project, _list_project } from "../../DAL/project";
import { useAppContext } from "../../hooks/AppContext";
import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import apibackground from "../../assets/images/api-background.jpg";
import apibackground2 from "../../assets/images/api-background2.jpg";

const Home = () => {
  const {
    setPostmanFile,
    isLoading,
    setIsLoading,
    setAddGrid,
    setSelectedEnvironment,
  } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();

  const [allWorkspaces, setAllWorkspaces] = useState();
  const [workspaceProjects, setWorkspaceProjects] = useState();
  const [activeBtnWorkspace, setActiveBtnWorkspace] = useState(null);
  const [activeBtnProject, setActiveBtnProject] = useState(null);
  const { project_id } = useParams();

  const getAllWorkspaces = async () => {
    const result = await _list_workspace();
    if (result.code == 200) {
      setAllWorkspaces(result.workspace_list);
      setIsLoading(false);
    }
  };
  const getProjects = async (id, index) => {
    setActiveBtnWorkspace(index);
    const result = await _list_project(id);
    if (result.code == 200) {
      console.log(result, "sdsdsdsdsdsdsd");
      setWorkspaceProjects(result.projects);
    }
  };
  const handleOpenProject = async (project_id, index) => {
    setActiveBtnProject(index);
    setIsLoading(true);
    setPostmanFile(null);
    localStorage.setItem("projectID", JSON.stringify(project_id));
    const result = await _detail_project(project_id);
    if (result.code == 200) {
      setPostmanFile(result.project);
      setSelectedEnvironment(
        result.project.environment.map((env) => {
          return { ...env, label: env.environment, value: env };
        })
      );
      setIsLoading(false);
      const baseUrl = result.project.environment.map((url) => {
        return url.url;
      });

      setAddGrid((prev) => {
        let project = prev[0];
        project = { ...project, url: baseUrl[0] };
        return [project];
      });
    } else {
      setIsLoading(false)
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getAllWorkspaces();
  }, []);
  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : allWorkspaces?.length > 0 ? (
        <div className="container py-5">
          <div className="mb-5">
            <h5
              style={{
                borderBottom: "2px solid #000",
                paddingBottom: "5px",
                display: "inline-block",
                marginBottom: "30px",
              }}
            >
              Recent Workspaces
            </h5>

            <ul className="list-unstyled d-flex">
              {allWorkspaces?.map((val, index) => {
                return (
                  <li
                    style={{
                      marginRight: "20px",
                      backgroundColor: "#e7e7e7",
                      border:
                        activeBtnWorkspace == index ? "1px solid #454" : "",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    key={index}
                    onClick={() => getProjects(val._id, index)}
                  >
                    <p
                      style={{
                        marginBottom: "0",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {val?.name}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          {workspaceProjects && (
            <div>
              <h5
                style={{
                  borderBottom: "2px solid #000",
                  paddingBottom: "5px",
                  display: "inline-block",
                  marginBottom: "30px",
                }}
              >
                Projects
              </h5>

              <ul className="list-unstyled d-flex">
                {workspaceProjects?.map((val, index) => {
                  return (
                    <li key={index}>
                      <Link
                        className={`text-decoration-none item`}
                        style={{
                          fontSize: "14px",
                          marginRight: "20px",
                          backgroundColor: "#e7e7e7",
                          padding: "5px 15px",
                          border:
                            activeBtnProject == index ? "2px inset #454" : "",
                          borderRadius: "30px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenProject(val._id, index)}
                        to={`/project/${val._id}`}
                      >
                        {val?.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      ) : (
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <img
              src={apibackground2}
              alt="background"
              width={300}
              height={300}
            />
            <h6 className="py-3">Add new workspace or project</h6>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
