import { invokeApi } from "../utils/invokeApi";

export const _user_profile = async () => {
    const requestObj = {
      path: `api/customer/detail_customer`,
      method: "GET",
      headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
    };
    return invokeApi(requestObj);
  };