import { useContext } from "react";
import { useState, createContext } from "react";

const Context = createContext();

const AppContext = ({ children }) => {
  const [workspaceArray, setWorkspaceArray] = useState();
  const [environmentArray, setEnvironmentArray] = useState([
    "Production",
    "Development",
    "Staging",
    "Local",
  ]);
  const [newProject, setNewProject] = useState([]);
  const [environmentVariable, setEnvironmentVariable] = useState([]);
  const [addGrid, setAddGrid] = useState([{ environment: "", url: "" }]);
  const [postmanFile, setPostmanFile] = useState();
  const [openDialog, setOpenDialog] = useState(false); //modal:add new workspace
  const [openModal, setOpenModal] = useState(false); //modal: add new project
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState([]);
  const [headerArray, setHeaderArray] = useState([]);

  const [currentEnvironmentSelected, setCurrentEnvironmentSelected] =
    useState(null); //header: save value from environment dropdown

  const bundle = {
    workspaceArray,
    setWorkspaceArray,
    environmentArray,
    setEnvironmentArray,
    newProject,
    setNewProject,
    addGrid,
    setAddGrid,
    environmentVariable,
    setEnvironmentVariable,
    postmanFile,
    setPostmanFile,
    openDialog,
    setOpenDialog,
    openModal,
    setOpenModal,
    isLoading,
    setIsLoading,
    currentEnvironmentSelected,
    setCurrentEnvironmentSelected,
    selectedEnvironment,
    setSelectedEnvironment,
    headerArray,
    setHeaderArray,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => useContext(Context);
