import { invokeApi } from "../utils/invokeApi";

export const _add_project = async (data) => {
  const requestObj = {
    path: `api/project/add_project`,
    method: "POST",
    postData: data,
    headers: {
        "Content-Type": "multipart/form-data",
        'x-sh-auth': localStorage.getItem('token'),
      },
  };
  return invokeApi(requestObj);
};

export const _list_project = async (workspace_id) => {
  const requestObj = {
    path: `api/project/list_project/${workspace_id}`,
    method: "GET",
    // postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_project = async (data,id) => {
  const requestObj = {
    path: `api/project/edit_project/${id}`,
    method: "PUT",
    postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _detail_project = async (id) => {
  const requestObj = {
    path: `api/project/detail_project/${id}`,
    method: "GET",
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_project = async (id) => {
  const requestObj = {
    path: `api/project/delete_project/${id}`,
    method: "Delete",
    // postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _upload_file = async (data) => {
  const requestObj = {
    path: `api/app_api/read_postman_file`,
    method: "POST",
    postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};
