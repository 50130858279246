import React from "react";
import Typography from "@mui/material/Typography";

import DescriptionIcon from "@mui/icons-material/Description";
import { CircularProgress } from "@mui/material";
import { useAppContext } from "../../hooks/AppContext";
import FolderComponent from "../Home/components/FolderComponent";
import APIComponent from "../Home/components/APIComponent";
import { useMemo } from "react";

const Project = () => {
  const {
    setOpenDialog,
    setOpenModal,
    workspaceArray,
    postmanFile,
    isLoading,
  } = useAppContext();

  const filteredDataMemoized = useMemo(() => {
    return postmanFile;
  }, [postmanFile]);

  return (
    <>
      <div className="container">
        {postmanFile ? (
          <>
            <div className="row">
              <div className="col-md-12">
                {postmanFile && (
                  <div className="py-3" style={{ marginTop: "65px" }}>
                    <>
                      <Typography variant="h5" className="mb-3">
                        {filteredDataMemoized.name}
                      </Typography>

                      <div className="mb-2">
                        {filteredDataMemoized?.item.map((x, index) => {
                          if (!!x?.item)
                            return (
                              <FolderComponent
                                index={`${index}-subindex`}
                                folder={x}
                                key={index}
                              />
                            );
                          else
                            return (
                              <APIComponent
                                apiData={x}
                                apiIndex={`${index}-subindex-apiindex`}
                                key={`${index}-subindex-apiindex`}
                              />
                            );
                        })}
                        {/* <NestedAccordion
                        folder={postmanFile}
                        token={token}
                        baseUri={baseUri}
                      /> */}
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : isLoading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ padding: "60px 0", marginTop: "65px" }}>
            <div className="d-flex flex-column align-items-center">
              <div
                style={{
                  border: "1px solid #f4f4f4",
                  background: "#f4f4f4",
                  borderRadius: "100%",
                  padding: "50px",
                  marginBottom: "30px",
                }}
              >
                <DescriptionIcon
                  style={{ color: "#dbdbdb", fontSize: "100px" }}
                />
              </div>
              <div
                className="text-center"
                style={{ maxWidth: "600px", marginBottom: "30px" }}
              >
                <Typography>
                  This is your personal, private workspace to play around in.
                  Only you can see the collections and APIs you create here -
                  unless you share them with your team.
                </Typography>
                {!!workspaceArray && workspaceArray?.length == 0 ? (
                  <Typography className="py-3">
                    Create{" "}
                    <strong
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenDialog("true")}
                    >
                      workspace
                    </strong>{" "}
                    and{" "}
                    <strong
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenModal("true")}
                    >
                      project
                    </strong>
                  </Typography>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Project;
