import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Add, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";

const EnvironmentDialogs = (props) => {
  const {
    newEnvironment,
    setNewEnvironment,
    environmentArray,
    setOpenAddEnvironment,
    openAddEnvironment
  } = props;


  const handleCloseEnvironmentDialog = () => {
    setOpenAddEnvironment(false);
  };

  const handleAddEnvironment = () => {
    console.log(newEnvironment,"new----------")
    if (newEnvironment) {
        environmentArray.push({newEnvironment});
      }
      setNewEnvironment("");
      handleCloseEnvironmentDialog();
  };

  return (
    <>
      {/* add workspace dialog */}
      <Dialog
        open={openAddEnvironment}
        onClose={handleCloseEnvironmentDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="d-flex justify-content-end pb-0">
          <IconButton
            onClick={handleCloseEnvironmentDialog}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText className="mb-3">
            Do you want to add a new environment?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="environment"
            label="Environment"
            value={newEnvironment}
            fullWidth
            size="small"
            onChange={(e) => setNewEnvironment(e.target.value)}
          />
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "16px",
          }}
        >
          <div>
            <Button
              onClick={handleCloseEnvironmentDialog}
              size="small"
              style={{
                lineHeight: "unset",
                textTransform: "capitalize",
                fontSize: "12px",
                marginRight:"10px"
              }}
              endIcon={<Cancel />}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddEnvironment}
              size="small"
              endIcon={<Add />}
              variant="contained"
              style={{ lineHeight: "unset" }}
            >
              Add
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {/* end */}
    </>
  );
};

export default EnvironmentDialogs;
