import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const HomeLayout = () => {
  const { pathname } = useLocation();
  if (localStorage.getItem("token")) {
    if (
      pathname === "/login" ||
      pathname === "/signup" ||
      pathname === "/forget-password"
    ) {
      return <Navigate to="/home" replace />;
    }
  }
  return <Outlet />;
};

export default HomeLayout;
