import { invokeApi } from "../utils/invokeApi";

export const _add_workspace = async (data) => {
  const requestObj = {
    path: `api/workspace/add_workspace`,
    method: "POST",
    postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
      },
  };
  return invokeApi(requestObj);
};

export const _list_workspace = async (data) => {
  const requestObj = {
    path: `api/workspace/list_workspace`,
    method: "POST",
    postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_workspace = async (data,id) => {
  const requestObj = {
    path: `api/workspace/edit_workspace/${id}`,
    method: "PUT",
    postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _detail_workspace = async (id) => {
  const requestObj = {
    path: `api/workspace/detail_workspace/${id}`,
    method: "GET",
    // postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_workspace = async (id) => {
  const requestObj = {
    path: `api/workspace/delete_workspace/${id}`,
    method: "Delete",
    // postData: data,
    headers: {
        'x-sh-auth': localStorage.getItem('token'),
    },
  };
  return invokeApi(requestObj);
};
