import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
// ---------------------------------------------------------
export default function MuiAutoComplete({
  label,
  options,
  value,
  onChange,
  disabled,
}) {
  const [inputValue, setInputValue] = React.useState("");
  // ---------------------------------------------------------
  const handleChange = (newValue) => {
    console.log(newValue);
    if (newValue) {
      onChange(newValue);
    } else {
      onChange();
    }
  };
  // console.log([options[0].environment],"valuevalue")
  // ---------------------------------------------------------
  return (
    <div>
      <Autocomplete
        value={value}
        disabled={disabled}
        // defaultValue={options[0].environment}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        sx={{ width: "170px", marginRight: "15px" }}
        size="small"
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option?.title === value?.title}
        id="controllable-states-demo"
        options={options}
        renderInput={(params) => (
          <TextField sx={{ width: "100%", fontSize: "14px" }} {...params} label={label} />
        )}
      />
    </div>
  );
}
MuiAutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
