import { Card, CardContent, Typography } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React from "react";
import { useState, useEffect } from "react";
import { _user_profile } from "../../DAL/user";
import profileavatar from "../../assets/images/profile-avatar.png";

const ViewUserProfile = () => {
  const [userProfileData, setuserProfileData] = useState();

  const getUserProfileData = async () => {
    const result = await _user_profile();
    if (result.code == 200) {
      console.log(result, "userProfileDatauserProfileData");
      setuserProfileData(result.customer);
    }
  };
  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <section className="vh-100" style={{ backgroundColor: "#f4f5f7" }}>
      <div className="container py-5 h-100">
        <div className="row justify-content-center h-100 mx-auto">
          <div className="mb-4 mb-lg-0">
            <Card className="mb-3" style={{ borderRadius: ".5rem" }}>
              <div className="row g-0">
                <div
                  className="col-md-4 gradient-custom text-center text-white py-5"
                  style={{
                    borderTopLeftRadius: ".5rem",
                    borderBottomLeftRadius: ".5rem",
                    background:
                      "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 4%, rgba(8,40,141,1) 20%, rgba(6,74,164,1) 44%, rgba(5,99,180,1) 68%, rgba(3,145,211,1) 88%, rgba(0,212,255,1) 100%)",
                    boxShadow: "2px 0px 12px #000",
                  }}
                >
                  <img
                    src={profileavatar}
                    alt="Avatar"
                    style={{ width: "150px" }}
                  />
                  {userProfileData && (
                    <Typography variant="h3">{`${userProfileData.first_name} ${userProfileData.last_name}`}</Typography>
                  )}
                </div>
                <div className="col-md-8">
                  <CardContent className="p-4">
                    <Typography tag="h6">Profile</Typography>
                    <hr className="mt-0 mb-4" />
                    <div
                      className="d-flex align-items-center"
                      style={{
                        boxShadow: "0px 0px 3px #000",
                        padding: "10px 10px",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography tag="h6" className="me-2">
                        Email:{" "}
                      </Typography>
                      {userProfileData && (
                        <Typography className="text-muted">
                          {userProfileData?.user_id?.email}
                        </Typography>
                      )}
                      {/* <BorderColorIcon /> */}
                    </div>
                  </CardContent>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewUserProfile;
